import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Intro from "@/views/Intro";
import Btc from "@/views/Btc";
import Contact from "@/views/Contact";
import Ethereum from "@/views/Ethereum";
import Ltc from "@/views/Ltc";
import AI from "../views/AI";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // {
    //     path: '/intro',
    //     name: 'Intro',
    //     component: Intro
    // },
    {
        path: '/prod/btc',
        name: 'btc',
        component: Btc
    },
    {
        path: '/prod/ethereum',
        name: 'eth',
        component: Ethereum
    },
    {
        path: '/prod/ltc',
        name: 'ltc',
        component: Ltc
    },
    {
        path: '/Contact',
        name: 'contact',
        component: Contact
    },
    // {
    //     path: '/ai',
    //     name: 'ai',
    //     component: AI
    // },
]

const router = new VueRouter({
    routes
})

export default router
