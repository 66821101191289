<template>
    <div class="main-wrapper">
        <!-- Hero Start -->
        <div class="section techwix-hero-section-04 d-flex align-items-center" style="background-image: url(assets/images/bg/banner_bg.jpg);">
            <img class="shape-1" src="assets/images/shape/hero4-shape1.png" alt="">
            <img class="shape-2" src="assets/images/shape/hero4-shape2.png" alt="">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <!-- Hero Content Start -->
                        <div class="hero-content text-center" style="overflow: unset">
                            <img src="../assets/img/minerva_logo_white.png" style="max-width: 70%; margin-bottom: 2em" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="200">
                            <h2 class="text-white" data-aos="zoom-in-up" data-aos-delay="700" data-aos-duration="1000">Building the cornerstone of the crypto mining industry.</h2>
                        </div>
                        <!-- Hero Content End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- Hero End -->

        <!-- Features Start -->
        <div class="section techwix-features-section" style="margin-top: -5em">
            <div class="container">
                <!-- Features Wrap Start -->
                <div class="features-wrap">
                    <div class="row" style="margin-top: 10em;">
                        <div class="col-xl-4 col-lg-6">
                            <!-- Features Item Start -->
                            <div class="features-item" style="background-image: url(assets/images/bg/feat-bg.jpg);">
                                <div class="features-icon">
                                    <img src="assets/images/feat-1.png" alt="">
                                </div>
                                <div class="features-content">
                                    <h3 class="title">Crypto ASICs</h3>
                                    <p class="box_content">R&D of cutting edge crypto ASICs </p>
                                </div>
                            </div>
                            <!-- Features Item End -->
                        </div>
                        <div class="col-xl-4 col-lg-6">
                            <!-- Features Item Start -->
                            <div class="features-item" style="background-image: url(assets/images/bg/feat-bg.jpg);">
                                <div class="features-icon">
                                    <img src="assets/images/feat-2.png" alt="">
                                </div>
                                <div class="features-content">
                                    <h3 class="title">Blockchain Miners</h3>
                                    <p class="box_content">A complete design & manufacturing system of blockchian miners</p>
                                </div>
                            </div>
                            <!-- Features Item End -->
                        </div>
                        <div class="col-xl-4 col-lg-6">
                            <!-- Features Item Start -->
                            <div class="features-item" style="background-image: url(assets/images/bg/feat-bg.jpg);">
                                <div class="features-icon">
                                    <img src="assets/images/feat-3.png" alt="">
                                </div>
                                <div class="features-content">
                                    <h3 class="title">Service & Consulting</h3>
                                    <p class="box_content">GLobal mining consulting and services</p>
                                </div>
                            </div>
                            <!-- Features Item End -->
                        </div>
                    </div>
                </div>
                <!-- Features Wrap End -->
            </div>
        </div>
        <!-- Features End -->

        <!-- Service Start -->
        <div class="section techwix-service-section-04 section-padding" style="padding: 5em 0 !important;">
            <div class="container">
                <!-- Service Wrap Start -->
                <div class="service-wrap">
                    <!-- Service Bottom Start -->
                    <div class="service-bottom" style="padding-top: 5em !important;">
                        <h1>PRODUCTS</h1>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <!-- Service Item Start -->
                                <div class="service-item-03">
                                    <div class="service-img">
                                        <a @click="go('/prod/btc')"><img src="../assets/img/miner.png" style="width: 100%" alt=""></a>
                                    </div>
                                    <div class="service-text">
                                        <h3 class="title"><a href="#" @click="go('/prod/btc')">BitCoin Miners</a></h3>
                                    </div>
                                </div>
                                <!-- Service Item End -->
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <!-- Service Item Start -->
                                <div class="service-item-03">
                                    <div class="service-img">
                                        <a @click="go('/prod/ethereum')"><img src="../assets/img/ether2.png" style="width: 100%" alt=""></a>
                                    </div>
                                    <div class="service-text">
                                        <h3 class="title"><a href="#" @click="go('/prod/ethereum')">Ethereum AISCs</a></h3>
                                    </div>
                                </div>
                                <!-- Service Item End -->
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <!-- Service Item Start -->
                                <div class="service-item-03">
                                    <div class="service-img">
                                        <a @click="go('/prod/ltc')"><img src="../assets/img/ltc.png" style="width: 100%" alt=""></a>
                                    </div>
                                    <div class="service-text">
                                        <h3 class="title"><a href="#" @click="go('/prod/ltc')">LTC & Doge ASICs</a></h3>
                                    </div>
                                </div>
                                <!-- Service Item End -->
                            </div>
                            <!--<div class="col-lg-3 col-md-6">-->
                                <!--&lt;!&ndash; Service Item Start &ndash;&gt;-->
                                <!--<div class="service-item-03">-->
                                    <!--<div class="service-img">-->
                                        <!--<a @click="go('/ai')"><img src="../assets/img/ai1_1.png" style="width: 100%;" alt=""></a>-->
                                    <!--</div>-->
                                    <!--<div class="service-text">-->
                                        <!--<h3 class="title"><a href="#" @click="go('/ai')">AI Prod & Service</a></h3>-->
                                    <!--</div>-->
                                <!--</div>-->
                                <!--&lt;!&ndash; Service Item End &ndash;&gt;-->
                            <!--</div>-->
                        </div>
<!--                        <div class="row">-->
<!--                            <div class="col-lg-12">-->
<!--                                <div class="more-service-content text-center">-->
<!--                                    <p>Learn more about <a href="service.html">More Services <i class="fas fa-long-arrow-alt-right"></i></a></p>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                    <!-- Service Bottom End -->
                </div>
                <!-- Service Wrap End -->
            </div>
        </div>
        <!-- Service End -->
        <!-- back to top start -->
        <div class="progress-wrap">
            <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
            </svg>
        </div>
        <!-- back to top end -->
        <div class="footer-copyright-area">
            <div class="container">
                <div class="footer-copyright-wrap">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <!-- Footer Copyright Text Start -->
                            <div class="copyright-text text-center">
                                <p>© Copyrights 2021 <a href="mailto:info@minervaic.com">MinerVa Technology</a> All rights Reserved. </p>
                            </div>
                            <!-- Footer Copyright Text End -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Home',
        methods:{
            go(path){
                this.$router.push(path);
            }
        }
    }
</script>

<style scoped>
    .logo_home {
        height: 7.5em; position: absolute; left: 38%; top: 45%
    }
    .service-text {
        background-color: rgba(255, 255, 255, 0.75) !important;
    }
    @media (max-width: 768px) {
        .logo_home {
            left: 23%;
            height: 3em;
        }
    }
    @media (min-width: 800px) and (max-width: 1000px) {
        .logo_home {
            left: 26%;
            height: 5em;
        }
    }
    .box_content {
        margin-top: 0.5em;
        font-size: 1.2em;
        line-height: 1.2em;
    }
    .features-item {
        height: 12em;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .slide-fade-enter-active {
        transition: all .8s ease;
    }

    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
